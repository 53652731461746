<template>
  <v-card flat class="details-panel">
    <v-card-title> Beställningsinformation</v-card-title>
    <v-divider />
    <v-card-subtitle> Adresser </v-card-subtitle>
		<address-count
			:variant="variant"
			:phoneCount="phoneCount"
			:addressCount="addressCount" />
    <v-divider />
		<paper-select />
    <v-divider />
		<ref-copy-select />
		<order-reference/>
  </v-card>
</template>

<script>
import PaperSelect from './PaperSelect/PaperSelect.vue'
import RefCopySelect from './RefCopySelect/RefCopySelect.vue'
import OrderReference from './OrderReference/OrderReference.vue'
import AddressCount from '../Common/AddressCount/AddressCount.vue'

export default {
	name: 'OrderDetails',
	components: {
		PaperSelect,
		RefCopySelect,
		OrderReference,
		AddressCount,
	},
	computed: {
		includePhoneNumbers() {
			return this.$store.state.targetAudience.includePhoneNumbers.value
		},
		addressCount() {
			return this.$store.getters.getAddressCount
		},
		phoneCount() {
			return this.$store.getters.getPhoneCount
		},
		variant() {
			return this.$store.state.session.variant
		},
	},
}
</script>

<style scoped lang="scss">
@import "./OrderDetails.scss";
</style>