<template>
	<v-dialog v-model="open" max-width="90vh">
		<template v-slot:activator="{ on, attrs }">
			<a href='#orderinfo' v-bind="attrs" v-on="on">
				{{ $t(linktext) }}
			</a>
		</template>
		<v-card>
			<v-card-title class="headline">
				Personadresserad utdelning
			</v-card-title>
			<v-card-text>
				Den personadressade utdelning når samtliga personer i den adressfil som du bekräftar innan beställning. Därmed når
				den även de som anger "Nej tack till reklam". Kriterierna för att adressaten skall finnas i adressfilen är att de:
				<br> - Adressen är koordinatsatt
				<br> - Adressaten är inte nixad (dsv har ej angivit att de inte vill ha adresserad direktreklam)
				<br>
				<br>Ditt utskick delas ut av brevbäraren som vanligt brev.
				<br>
				<br>Adresserna köps av Dun & Bradstreet
			</v-card-text>
			<footer>
				<v-btn small color="primary darken-1" @click="open = false">
					{{ $t('Close') }}
				</v-btn>
			</footer>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	name: 'OrderInfo',
	data: () => ({
		open: false,
	}),
	props: {
		distributor: {
			type: String,
		},
		product: {
			type: String,
		},
		linktext: {
			type: String,
			default: '[read more]',
		},
	},
}
</script>

<style lang="scss" scoped>
@import './OrderInfo.scss';
</style>