import { checkDistributorZipcodes } from '../services/addresses'

export const STATUS = {
	success: 'CSV/VALIDATION_STATUS/PASSED',
	warning: 'CSV/VALIDATION_STATUS/WARNING',
	error: 'CSV/VALIDATION_STATUS/ERROR',
}

export const FORMAT = {
	standard: 'CSV/FORMAT/STANDARD',
	standardplus: 'CSV/FORMAT/STANDARD/PLUS',
	extended: 'CSV/FORMAT/EXTENDED',
	extendedplus: 'CSV/FORMAT/EXTENDED/PLUS',
}

const checkString = ({ str, required, min, max }) => {
	if (!str) {
		if	(required) {
			return {
				status: required,
				message: 'No value provided',
				value: str,
			}
		}
		return {
			status: STATUS.warning,
			message: 'Invalid input',
			value: str,
		}
	}
	if (/[„†™Ž½¿"'%&;^${}[]`~#=<>@\\]/g.test(str)) {
		return {
			status: STATUS.error,
			message: 'Invalid characters',
			value: str,
		}
	}
	if (min && str.length < min) {
		return {
			status: STATUS.error,
			message: `A minimum length of ${min} is required`,
			value: str,
		}
	}
	if (max && str.length > max) {
		return {
			status: STATUS.error,
			message: `A maximum length of ${max} is required`,
			value: str,
		}
	}
	return {
		status: STATUS.success,
		value: str.trim(),
	}
}

const validators = {
	name: (name) => checkString({
		str: name,
		required: STATUS.error,
		min: 3,
	}),
	address: (address) => checkString({
		str: address,
		required: STATUS.error,
		min: 2,
	}),
	postal: (postal) => {
		const { status, value, message } = checkString({
			str: postal,
			required: STATUS.error,
			min: 5,
		})
		if (status === STATUS.error) {
			return { status, value, message }
		}
		const zipmatch = /^[0-9 ]+/.exec(value)
		const tmpcity = zipmatch && value.substring(zipmatch[0].length)
		if (!zipmatch) {
			return {
				status: STATUS.error,
				message: 'Missing zipcode',
				value,
			}
		}
		if (!tmpcity) {
			return {
				status: STATUS.error,
				message: 'Missing city',
				value,
			}
		}
		const zipcode = zipmatch[0].replace(/ /g, '')
		const city = tmpcity.trim()

		if (!/^[0-9]{5,5}$/.test(zipcode)) {
			return {
				status: STATUS.error,
				message: 'Not a valid zipcode',
				value: [zipcode, city].join(' '),
			}
		}
		if (!/^.{2,}$/.test(city)) {
			return {
				status: STATUS.error,
				message: 'The city name looks a bit short, yes?',
				value: [zipcode, city].join(' '),
			}
		}

		return {
			status: STATUS.success,
			value: [zipcode, city].join(' '),
		}
	},
	phone: (phone) => {
		const { status, value, message } = checkString({
			str: phone,
			required: STATUS.warning,
			min: 7,
		})
		if (status === STATUS.error) {
			return { status, value, message }
		}
		const phonematch = /^[0-9 -]{7,}$/.exec(value)
		if (!phonematch) {
			return {
				status: STATUS.warning,
				message: 'Looks like an invalid phone number',
				value,
			}
		}
		return {
			status: STATUS.success,
			value: phonematch[0].replace(/[ -]?/g, ''),
		}
	},
	optional: (optional) => checkString({ str: optional, required: STATUS.success }),
}

export const formats = [
	{ id: FORMAT.standard,
		name: 'Standard',
		columns: [
			{ id: 'name', name: 'Name', validator: validators.name },
			{ id: 'address', name: 'Address', validator: validators.address },
			{ id: 'postal', name: 'Zipcode City', validator: validators.postal },
		],
	},
	{ id: FORMAT.standardplus,
		name: 'Standard + Phone Number',
		columns: [
			{ id: 'name', name: 'Name', validator: validators.name },
			{ id: 'address', name: 'Address', validator: validators.address },
			{ id: 'postal', name: 'Zipcode City', validator: validators.postal },
			{ id: 'phone', name: 'Phone number', validator: validators.phone },
		],
	},
	{ id: FORMAT.extended,
		name: 'Extended',
		columns: [
			{ id: 'name', name: 'Name', validator: validators.name },
			{ id: 'address', name: 'Address', validator: validators.address },
			{ id: 'postal', name: 'Zipcode City', validator: validators.postal },
			{ id: 'co', name: 'Optional C/O', validator: validators.optional },
			{ id: 'org', name: 'Optional Organization', validator: validators.optional },
		],
	},
	{ id: FORMAT.extendedplus,
		name: 'Extended + Phone Number',
		columns: [
			{ id: 'name', name: 'Name', validator: validators.name },
			{ id: 'address', name: 'Address', validator: validators.address },
			{ id: 'postal', name: 'Zipcode City', validator: validators.postal },
			{ id: 'phone', name: 'Phone number', validator: validators.phone },
			{ id: 'co', name: 'Optional C/O', validator: validators.optional },
			{ id: 'org', name: 'Optional Organization', validator: validators.optional },
		],
	},
]

export const fuzzyFormatFinder = (csvdata) => {
	const samples = ((dataset) => {
		const rows = []
		while (rows.length < 100) {
			// Skip first and last line
			const index = Math.round(Math.random() * (dataset.length - 2)) + 1
			rows.push(dataset[index])
		}
		return rows
	})(csvdata)

	const checkSample = (format, sample) => {
		const result = format.columns.reduce((ack, { validator }, index) => {
			if (sample.length === 0) {
				return ack
			}
			let score = 0
			if (!sample[index]) {
				score -= 1
			}
			if (validator(sample[index]).status === STATUS.warning) {
				score -= 1
			} else if (validator(sample[index]).status === STATUS.error) {
				score -= 1
			} else if (validator(sample[index]).value && validator(sample[index]).status === STATUS.success) {
				score += 1
			}
			return ack + score
		}, 0)
		return result
	}

	const checkSamples = (format, items) => items.reduce((score, sample) => score + checkSample(format, sample), 0)

	const checkFormats = (fmts, smpls) => {
		const [fmt] = fmts.reduce((ack, next) => {
			const [curfmt, curscore] = ack
			const [nextfmt, nextscore] = [next, checkSamples(next, smpls)]
			if (nextscore > curscore) {
				return [nextfmt, nextscore]
			}
			return [curfmt, curscore]
		}, [null, -1])
		return fmt
	}

	const format = checkFormats(formats, samples)
	return format
}

export const annotate = (format, csvdata) => {
	if (!format) throw new Error('Could not find format definition')
	if (!(csvdata instanceof Array)) throw new Error('Data is not correctly parsed...')

	return csvdata.map((row) => format.columns.map(({ validator, ...meta }, colIndex) => ({
		...meta,
		...validator(row[colIndex]),
	})))
}

export const addAnnotatedLineNumber = ((csvdata) => csvdata.map((row, index) => [{ id: 'line', name: 'Line Number', status: STATUS.success, value: index }, ...row]))

export const annotateZipCodes = async ({ apiToken, csvData }) => {
	if (!csvData.length) throw new Error('No lines to annotate')
	if (!csvData[0].length) throw new Error('No columns to annotate')

	const zipcodes = csvData.reduce((zips, row) => {
		const { value, status } = row.find(col => col.id === 'postal')
		if (!(status === STATUS.success && value)) return zips

		const [zip] = value.trim().split(' ')
		if (isNaN(+zip)) return zips
		if (zips.some(z => z === +zip)) return zips

		return [...zips, +zip]
	}, [])

	const { invalid: invalidCitymailZips } = await checkDistributorZipcodes({ apiToken, distributor: 'citymail', zipcodes })

	const annotated = csvData.map((row) => {
		const { value, status } = row.find(col => col.id === 'postal')
		if (!(status === STATUS.success && value)) return row

		const [zip] = value.trim().split(' ')
		if (isNaN(+zip)) return row

		const copy = row.slice()
		return copy.map(col => (col.id === 'postal'
			? { ...col, citymail: !invalidCitymailZips.some(z => +z === +zip) }
			: { ...col }
		))
	})

	return annotated
}