<template>
		<div class='paper-weight-options' v-if="paper.available.length > 1">
    <v-card-subtitle> Välj gramvikt på ditt DR </v-card-subtitle>
    <v-card-text>
      <v-radio-group v-model="paper.selected" row>
        <v-radio
          :key="variant.value"
          v-for="variant in paper.available"
          :value="variant.value"
          :label="variant.priceText"
					:hidden="true"
        />
      </v-radio-group>
    </v-card-text>
		</div>
</template>

<script>
export default {
	name: 'PaperSelect',
	computed: {
		paperWeightOptions() {
			// { options: [ { paperWeight, extraPriceText }, ... ], overrideWeight }
			return this.$store.state.session.paperWeightOptions
		},
	},
	data: () => ({
		paper: {
			selected: '300',
			available: [
				{ priceText: '300g', value: '300' },
			],
		},
		customer: {
			orderReferenceEx: false,
			brokerName: 'allan',
			brokerAttention: '',
			brokerAddress: '',
			brokerPostCode: '',
		},
	}),
	methods: {
		populate(paperWeightOptions) {
			const { options, overrideWeight } = paperWeightOptions
			if (overrideWeight) {
				const priceText = ''
				const value = overrideWeight
				this.paper.selected = overrideWeight
				this.paper.available = [{ value, priceText }]
			} else if (options) {
				this.paper.available = [
					...this.paper.available,
					...options
						.filter(({ paperWeight }) => !this.paper.available.some(({ value }) => +value === +paperWeight))
						.map(({ extraPriceText, paperWeight }) => ({ priceText: extraPriceText, value: paperWeight })),
				]
			}
		},
	},
	mounted() {
		this.populate(this.paperWeightOptions)
	},
	watch: {
		paperWeightOptions(incoming) {
			this.populate(incoming)
		},
	},
}
</script>

<style lang="scss" scoped>
	@import './PaperSelect.scss'
</style>