<template>
  <form class="grid">
    <div class="grid-heading">
      <h1>
        <v-icon small color="warning">fas fa-exclamation-triangle</v-icon>
        <span v-html="$t('HolidayHomes.heading')"></span>
      </h1>
      <p>{{ $t("HolidayHomes.about") }}</p>
    </div>
    <div class="grid-info">
      <h2>{{ $t("HolidayHomes.infoHead") }}</h2>
      <p v-html="$t('HolidayHomes.infoText')"></p>
    </div>
    <form class="grid-inputs" @submit.prevent>
      <v-card class="input-group" elevation="3" raised>
        <p class="input-description">
          {{ $t("HolidayHomes.municipalitySelectDescription") }}:
        </p>
        <v-select
          :label="$t('HolidayHomes.municipalitySelectLabel')"
          :items="municipalityOptions"
          item-text="municipalityName"
          item-value="id"
					v-model="municipalities"
          multiple
        />
      </v-card>
      <v-card class="input-group" elevation="3" raised>
        <p
          class="input-description"
          v-html="$t('HolidayHomes.extraInfoDescription')"
        ></p>
        <v-textarea v-model="instructions" label="Extra information" />
      </v-card>
      <v-card v-if="enablePhoneNumbers" class="input-group" elevation="3" raised>
        <p
          class="input-description"
          v-html="$t('HolidayHomes.phoneNumberDescription')"
        ></p>
        <v-checkbox v-model="includePhoneNumbers" :label="$t('HolidayHomes.phoneNumberLabel')" />
      </v-card>
    </form>
    <div class="grid-footer">
      <v-btn color="secondary" @click.prevent="handleCancel">
        <v-icon>fas fa-ban</v-icon>
        {{ $t("Cancel") }}
      </v-btn>
      <v-btn color="primary" @click.prevent="handleOrder" :disabled="!municipalities || !municipalities.length">
        <v-icon>fas fa-check</v-icon>
        {{ $t("Order") }}
      </v-btn>
    </div>
  </form>
</template>

<script>
import { pick } from 'lodash'
import { fetchMunicipalities } from '../../services/data'

export default {
	name: 'HolidayHomesPanel',
	data: () => ({
		initializing: false,
		processing: false,
		municipalityOptions: [],
		municipalities: [],
		instructions: '',
		includePhoneNumbers: false,
	}),
	computed: {
		enablePhoneNumbers() {
			return this.$store.state.session.overrideModules.enablePhoneNumbers
		},
	},
	methods: {
		populateForm() {
			const {
				municipalities,
				instructions,
				includePhoneNumbers,
			} = this.$store.state.holidayHomes

			this.municipalities = municipalities
			this.instructions = instructions
			this.includePhoneNumbers = includePhoneNumbers
		},
		async populateOptions() {
			const apiToken = this.$store.state.session.apiToken
			this.municipalityOptions = await fetchMunicipalities({ apiToken })
		},
		handleCancel() {
			this.$router.push('/')
		},
		async handleOrder() {
			this.processing = true
			const orderData = pick(this, ['municipalities', 'instructions', 'includePhoneNumbers'])
			await this.$store.dispatch('placeHolidayHomesOrder', orderData)
			this.processing = false
		},
	},
	async mounted() {
		this.initializing = true
		await this.populateOptions()
		this.populateForm()
		this.initializing = false
	},
}
</script>

<style lang="scss" scoped>
@import "./HolidayHomesPanel.scss";
</style>