<template>
	<div>
    <v-card-text>
      <form name="referenceCopy" v-on:submit.prevent="onSubmit">
        <v-switch
					v-if="enableReferenceCopy"
          v-model="sendCopy"
          label="Beställ referensex. 8 st - kostnad 50 kr"
					@change="handleChange"
        ></v-switch>
      </form>
    </v-card-text>
		<v-divider v-if="enableReferenceCopy" />
	</div>
</template>

<script>
export default {
	name: 'RefCopySelect',
	data: () => ({
		sendCopy: false,
	}),
	methods: {
		handleChange() {
			this.$store.dispatch('setRefCopy', this.sendCopy)
		},
	},
	computed: {
		/* this.$store.state.session.refCopyConfig: { refCopyOverride, disableRefCopy, isCheckedDefault } */
		enableReferenceCopy() {
			return !this.$store.state.session.refCopyConfig.disableRefCopy
		},
		isCheckedDefault() {
			return this.$store.state.session.refCopyConfig.isCheckedDefault
		},
	},
	mounted() {
		this.sendCopy = !!(this.isCheckedDefault)
	},
	watch: {
		isCheckedDefault(incoming) {
			this.sendCopy = !!(incoming)
		},
	},
}
</script>

<style lang="scss" scoped>
	@import './RefCopySelect.scss'
</style>