<template>
  <ViewContainer>
		<Distributions />
		<OrderDetails />
    <div class="footer">
      <v-btn
				:disabled="!submittable"
				color="primary"
				@click="submitBooking">
					{{ $t('Save and continue') }}
				</v-btn>
    </div>
  </ViewContainer>
</template>
<script>
import ViewContainer from '../components/ViewContainer.vue'
import { Distributions, OrderDetails } from '../components/DistributionForm'

export default {
	name: 'Distribution',
	components: {
		ViewContainer,
		Distributions,
		OrderDetails,
	},
	data: () => ({
		tab: 0,
	}),
	computed: {
		submittable() {
			const { getters, state } = this.$store
			const workflow = state.workflow.join('/')
			const { orderData, addressCount } = getters.getFinalBookingData
			switch (workflow) {
			case 'map/areas':
			case 'map/circle':
			case 'map/areacodes':
			default:
				return !!(
					addressCount
					&& orderData.deadlineDate
					&& orderData.reference.brokerName
					&& orderData.reference.companyName
					&& orderData.reference.address
					&& orderData.reference.postcode
				)
			}
		},
	},
	methods: {
		submitBooking() {
			this.$store.dispatch('submitBooking')
		},
	},
	mounted() {
		if (this.$store.state.workflow.length === 0) {
			this.$router.push('/')
		}
	},
}
</script>

<style lang="scss" scoped>
@import "./Distribution.scss";
</style>
