<template>
  <div class="fileupload">
		<Modal :open="modal" @close="() => { modal = false }">
			<template slot="title">
				{{ modal && modal.title }}
			</template>
			{{ modal && $t(`FileUpload.${modal.message}`) }}
		</Modal>
    <div class="cards">
      <v-card>
        <v-card-title>{{ $t('FileUpload.Upload address file') }}</v-card-title>
        <v-card-text>
					<div class='instructions step'>
						<v-icon>fas fa-info-circle</v-icon>
						<a
							href="//elements.webtopsolutions.com/elements/DMDirekt/InstruktionAdressfil.pdf"
							target="_blank"
						>
							{{ $t('FileUpload.Instructions for address file') }}
						</a>
					</div>
          <v-form
						@submit.prevent
						class="upload-form">
            <div class="input-file">
              <v-file-input
                dense
                outlined
                clearable
                show-size
                accept=".csv"
                :label="$t('FileUpload.Select file to upload')"
                v-model="file"
                :loading="loading"
                :disabled="working || locked"
								:encoding="encoding"
								@change="detectFileEncoding"
              />
              <div>
                <v-btn
                  class="cta"
                  color="primary"
                  :disabled="!file || working || locked"
                  @click="handleFileUpload"
                  >{{ $t('FileUpload.Upload') }}</v-btn
                >
              </div>
            </div>
            <div class="input-csv-format">
              <v-radio-group
                dense
                row
								:disabled="!locked || loading"
                v-model="csvFormatId"
                hide-details
                class="radio-csv-format"
              >
                <v-radio
                  v-for="{ id, name, columns } in csvFormats"
                  :key="id"
                  :value="id"
									active-class="active-radio"
                  :label="$t(`FileUpload.${name}`)"
                  :title="columns.map(({ name }) => name).join(';')"
                />
              </v-radio-group>
              <div v-if="currentCsvFormat" class="csv-format-description">
								<p>
									{{ $t('FileUpload.About Selected Format') }}
								</p>
								<dl>
									<dt>{{ $t('FileUpload.Selected Format') }}:</dt>
									<dd>{{ $t(`FileUpload.${currentCsvFormat.name}`) }}</dd>
									<dt>{{ $t('FileUpload.Format Description') }}</dt>
									<dd>{{ currentFormatDescription }}</dd>
								</dl>
              </div>
            </div>
          </v-form>
          <div v-if="currentCsvFormat" class="csv-data">
						<div class="results-info">
							<h1>
								<v-icon color="success">fas fa-check-circle</v-icon>
								{{ $t('FileUpload.The file contains') }} {{ linesValid.length }} {{ $t('FileUpload.valid addresses') }}.
							</h1>
							<ul>
								<li v-if="linesValid.length && (linesWarn.length || linesError.length)">
									<v-icon small color="success">fas fa-check-circle</v-icon>
										{{ $t('FileUpload.Addresses without errors will be distributed') }}.
									</li>
								<li v-if="linesWarn.length">
									<v-icon small color="warning">fas fa-exclamation-triangle</v-icon>
										{{ $t('FileUpload.Addresses with warnings but not errors will be distributed') }}.
									</li>
								<li v-if="linesError.length">
									<v-icon small color="error">fas fa-exclamation-triangle</v-icon>
										{{ $t('FileUpload.Addresses with errors will not be distributed') }}.
									</li>
							</ul>
							<p v-if="linesWarn.length || linesError.length">
								Om din fil innehåller varningar eller fel kan det vara bra att kontrollera varför i tabellen här under.
							</p>
						</div>
						<div class='tabletools'>
							<span v-if="linesValid.length">
								<span :class="{ faint: csvFilter && csvFilter !== 'linesValid' }">
								<v-icon small color="success">fas fa-check-circle</v-icon>
								{{ linesValid.length }} {{ $t('FileUpload.Valid lines') }}
								</span>
								<v-btn v-if="csvFilter !== 'linesValid'" x-small text @click="setCsvFilter('linesValid')">[{{ $t('FileUpload.Show') }}]</v-btn>
								<v-btn v-else x-small text @click="setCsvFilter()">[{{ $t('FileUpload.Hide') }}]</v-btn>
							</span>
							<span v-if="linesWarn.length">
								<span :class="{ faint: csvFilter && csvFilter !== 'linesWarn' }">
								<v-icon small color="warning">fas fa-exclamation-triangle</v-icon>
								{{ linesWarn.length }} {{ $t('FileUpload.Warnings') }}
								</span>
								<v-btn v-if="csvFilter !== 'linesWarn'" x-small text @click="setCsvFilter('linesWarn')">[{{ $t('FileUpload.Show') }}]</v-btn>
								<v-btn v-else x-small text @click="setCsvFilter()">[{{ $t('FileUpload.Hide') }}]</v-btn>
							</span>
							<span v-if="linesError.length">
								<span :class="{ faint: csvFilter && csvFilter !== 'linesError' }">
								<v-icon small color="error">fas fa-exclamation-triangle</v-icon>
								{{ linesError.length }} {{ $t('FileUpload.Errors') }}
								</span>
								<v-btn v-if="csvFilter !== 'linesError'" x-small text @click="setCsvFilter('linesError')">[{{ $t('FileUpload.Show') }}]</v-btn>
								<v-btn v-else x-small text @click="setCsvFilter()">[{{ $t('FileUpload.Hide') }}]</v-btn>
							</span>
						</div>
						<div :class="{ tablewrapper: true, open: !!csvFilter }">
							<table>
								<thead>
									<tr v-if="csvLinesOnDisplay.length">
										<th>Line number</th>
										<th
											:key="index"
											v-for="(column, index) in currentCsvFormat.columns"
										>
											{{ column.name }}
										</th>
									</tr>
								</thead>
								<tbody v-if="csvLinesOnDisplay.length">
									<tr
										class="csv-tr"
										:key="rindex"
										v-for="(row, rindex) in csvLinesOnDisplay"
									>
										<td :key="cindex" v-for="(data, cindex) in row">
											<v-icon
												small
												v-if="data.status !== STATUS.success"
												:color="(data.status === STATUS.error && 'error') || (data.status === STATUS.warning && 'warning') || 'secondary'"
												:title="$t(`FileUpload.${data.message}`)"
											>
												fas fa-exclamation-triangle
											</v-icon>
											{{ data.value }}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
          </div>
					<div class='buttons'>
						<v-btn color="secondary" @click="() => cancel()" :disabled="!this.locked || this.working">
							<v-icon small>fas fa-ban</v-icon>
							{{ $t('FileUpload.Cancel') }}
						</v-btn>
						<v-btn color="primary" :disabled="!(this.linesValid.length && this.csvFormatId)" @click="nextStep">
							<v-icon small>fas fa-check</v-icon>
							{{ $t('FileUpload.Continue') }}
						</v-btn>
					</div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import jschardet from 'jschardet'
import Papa from 'papaparse'
import { STATUS, annotate, addAnnotatedLineNumber, annotateZipCodes, formats, fuzzyFormatFinder } from '../../utils/csv'
import { Modal } from '../Common'

export default {
	name: 'FileUploadPanel',
	components: { Modal },
	data: () => ({
		STATUS,
		modal: false,
		loading: false,
		locked: false,
		processing: false,
		showInvalidCsvLines: false,
		csvFormatId: null,
		file: null,
		data: null,
		csvFilter: null,
		csvLines: [],
		csvLinesOnDisplay: [],
		parseErrors: [],
		parseMeta: { },
		encoding: 'utf8',
	}),
	computed: {
		working() {
			return this.loading || this.validating
		},
		csvFormats() {
			if (this.enablePhoneNumbers) {
				return formats
			}
			return formats.filter(item => !item.name.includes('Phone Number'))
		},
		currentCsvFormat() {
			return this.csvFormats.find(({ id }) => id === this.csvFormatId)
		},
		currentFormatDescription() {
			return this.currentCsvFormat.columns.map(({ name }) => this.$t(`FileUpload.${name}`)).join(';')
		},
		linesValid() {
			return this.csvLines.filter(line => line.every(column => column.status !== STATUS.error))
		},
		linesWarn() {
			return this.csvLines.filter(line => line.every(column => column.status !== STATUS.error) && line.some(column => column.status === STATUS.warning))
		},
		linesError() {
			return this.csvLines.filter(line => line.some(column => column.status === STATUS.error))
		},
		enablePhoneNumbers() {
			return this.$store.state.session.overrideModules.enablePhoneNumbers
		},
	},
	watch: {
		csvFormatId() {
			this.csvFilter = null
			this.csvLinesOnDisplay = []
			this.processCsv()
		},
	},
	methods: {
		setCsvFilter(filterName) {
			switch (filterName) {
			case 'linesError':
			case 'linesWarn':
			case 'linesValid':
				this.csvFilter = filterName
				this.csvLinesOnDisplay = this[filterName]
				break
			default:
				this.csvFilter = null
				setTimeout(() => { this.csvLinesOnDisplay = [] }, 750)
				break
			}
		},
		cancel(error) {
			this.setCsvFilter()
			this.loading = false
			this.locked = false
			this.file = null
			this.csvLines = []
			this.parseErrors = []
			this.csvFormatId = null
			if (error) {
				this.modal = {
					title: this.$t('FileUpload.Not a valid file'),
					message: error.message,
				}
			}
		},
		detectFileEncoding() {
			this.loading = true
			this.locked = true
			const fileReader = new FileReader()
			fileReader.onload = ({ target: { result } }) => {
				const { encoding } = jschardet.detect(result)
				// This seems to mess up with files with double scandinavian characters, e.g. "ää".
				// It detected ISO-8859-8 (Hebrew) when it should have been ISO-8859-1 -- so we'll
				// just hardcode anything 8859 related to ISO-8859-1
				if (encoding.indexOf('ISO-8859') !== -1) {
					this.encoding = 'ISO-8859-1'
				// windows-1251 encoding does not work for scandinavian characters like ä,ö,å.
				// Hardcoding windows-1251 to ISO-8859-1 seems to fix the issue
				} else if (encoding.indexOf('windows-1251') !== -1) {
					this.encoding = 'ISO-8859-1'
				} else {
					this.encoding = encoding
				}
				this.loading = false
				this.locked = false
			}
			fileReader.readAsBinaryString(this.file)
		},
		async handleFileUpload() {
			this.loading = true
			this.locked = true
			try {
				const csvData = await new Promise((resolve, reject) => {
					const fileReader = new FileReader()
					fileReader.onload = ({ target: { result } }) => {
						this.fileContent = result.split(/[\r\n]+/g).filter(line => line.length > 0).join('\n')
						const { data, errors } = Papa.parse(this.fileContent)
						if (errors.length) return reject(new Error(errors.pop()))
						return resolve(data)
					}
					fileReader.readAsText(this.file, this.encoding)
				})

				const format = fuzzyFormatFinder(csvData)
				if (!format) throw new Error('Invalid format')

				this.csvFormatId = format.id
				this.data = csvData
				this.processCsv()
			} catch (e) {
				this.cancel(e)
			} finally {
				this.loading = false
			}
		},
		processCsv() {
			if (this.currentCsvFormat && this.data) {
				try {
					this.processing = true
					const annotated = annotate(this.currentCsvFormat, this.data)
					if (!annotated.length) throw new Error('No valid data found!')
					this.csvLines = addAnnotatedLineNumber(annotated)
				} catch (e) {
					console.log('error in processCsv: ', e.message)
				} finally {
					this.processing = false
				}
			}
		},
		async nextStep() {
			this.processing = true
			try {
				if (this.linesValid.length && this.csvFormatId) {
					const addresses = (await annotateZipCodes({
						apiToken: this.$store.state.session.apiToken,
						format: this.currentCsvFormat,
						csvData: this.linesValid,
					}))

					this.$store.dispatch('setFileUpload', {
						addresses,
						format: this.csvFormatId,
						originalFileName: this.file.name,
					})
					this.$router.push('/distribution')
				}
			} catch (e) {
				console.log(`Error in 'nextStep': ${e.message}`)
			} finally {
				this.processing = false
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import "./FileUploadPanel.scss";
</style>