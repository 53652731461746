<template>
  <v-card class="card" flat>
    <v-card-title class="card-title" v-if="showIneligible">
      <span>{{ $t("Ineligible addresses") }}</span>
      <v-btn text small @click="showIneligible = false">
        <span>{{$t('Close')}}</span> <v-icon small>fas fa-times</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="text-section" v-if="showIneligible">
      <table>
        <tr :key="address.address" v-for="address in citymailIneligible">
          <td>{{ address.address }}</td>
          <td>{{ address.count }} st.</td>
        </tr>
      </table>
    </v-card-text>

    <v-card-title v-if="!showIneligible">
      {{ $t("Select Distribution") }}
    </v-card-title>
    <v-card-text
      v-if="!showIneligible && citymailIneligible.length && !ineligibleDone"
    >
      {{$t('The selection contains')}} {{ recipientCount(citymailIneligible) }} {{$t('recipients')}}
      <a text x-small href="javascript:" @click="showIneligible = true"
        >[{{$t('show')}}]</a
      >
			{{$t('that can not be distributed with citymail. Select one of the following options:')}}
      <v-radio-group v-model="ineligibleChoice">
        <v-radio
          value="postnord"
          :label="`${$t('Distribute with Postnord')} (${$t('All')} ${recipientCount(addresses)} ${$t('addresses')})`"
        />
        <v-radio
          :disabled="!recipientCount(citymailEligible)"
          value="citymail"
          small
          :label="`${$t('Distribute with Citymail')}
						(${recipientCount(citymailEligible)} av
						${recipientCount(addresses)} ${$t('addresses')})`"
        />
      </v-radio-group>
      <v-btn
        small
        color="primary"
        :disabled="!ineligibleChoice"
        @click="confirmIneligibleChoice"
        >{{$t('Confirm')}}</v-btn
      >
    </v-card-text>

    <v-card-text
      v-if="
        !showIneligible && (citymailIneligible.length === 0 || ineligibleDone)
      "
    >
      <v-tabs v-model="tab">
        <v-tab
          :key="distribution.id"
          :disabled="!distribution.enabled"
          v-for="distribution in distributions"
        >
          <img :src="distribution.logo" />
          <span class="tablabel">{{ distribution.name }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          :key="distribution.id"
          v-for="(distribution, index) in distributions"
        >
          <DistributionDates
            :distribution="distribution"
            :index="index"
            :ready="ready"
						:maxAmount="getMaxAmountForAdr"
						:addressCount="addressCount"
						:citymailEligible="citymailEligible"
            @handleDateSelect="handleDateSelect"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import { fetchBookingDates } from '../../services/booking/index'
import DistributionDates from './DistributionDates'
import citymail32 from '../../assets/CityMail32px.png'
import postnord32 from '../../assets/postnord32px.png'

export default {
	components: { DistributionDates },
	name: 'Distributions',
	data: () => ({
		tab: 0,
		components: {
			DistributionDates,
		},
		ready: false,
		initialized: false,
		dateSelected: null,
		showIneligible: false,
		ineligibleChoice: null,
		ineligibleDone: false,
		distributions: [
			{
				id: 'citymail',
				enabled: true,
				logo: citymail32,
				name: 'CityMail ADR',
				description: 'Personadresserad utdelning via CityMail',
				orderinfo: 'Detaljerad information om distribution',
				path: ['citymail', 'adr'],
				dateSelected: null,
				dates: [],
				maxAmount: 0,
			},
			{
				id: 'postnord',
				enabled: true,
				logo: postnord32,
				name: 'PostNord ADR',
				description: 'Personadresserad utdelning via PostNord',
				extraDescription: 'OBS, utdelning med PostNord har ett högre pris än utdelning med CityMail. Vi rekommenderar att utdelning beställs med CityMail istället för PostNord',
				orderinfo: 'Detaljerad information om distribution',
				path: ['posten', 'adr'],
				dateSelected: null,
				dates: [],
				maxAmount: 0,
			},
		],
	}),
	computed: {
		apiToken() {
			return this.$store.state.session.apiToken
		},
		excludeList() {
			return this.$store.getters.getAddressesToExclude
		},
		addresses() {
			return this.$store.getters.getAddresses.filter(({ address }) =>
				!this.excludeList.some(exclude => exclude.address === address))
		},
		citymailIneligible() {
			return (this.addresses
				.filter(({ citymail }) => !citymail)
			)
		},
		citymailEligible() {
			return (this.addresses
				.filter(({ citymail }) => !!citymail)
			)
		},
		getMaxAmountForAdr() {
			return this.$store.getters.getMaxAmountForAdr
		},
		resultLimit() {
			return this.$store.state.session.configData.resultLimit
		},
		addressCount() {
			return this.$store.getters.getFinalBookingData.addressCount
		},
	},
	methods: {
		recipientCount(addresses) {
			return addresses.reduce((ack, { count }) => ack + count, 0)
		},
		handleDateSelect(selectedIndex) {
			this.distributions.forEach((distribution, index) => {
				if (selectedIndex !== index) {
					Object.assign(distribution, { dateSelected: null })
				} else {
					this.dateSelected = distribution.dateSelected
					this.$store.dispatch('setDeadlineDateParams', {
						deadlineDate: this.dateSelected,
						distribution: distribution.path,
					})
				}
			})
		},
		confirmIneligibleChoice() {
			if (this.ineligibleChoice === 'citymail') {
				this.$store.dispatch('dropCitymailIneligible')
			} else {
				this.distributions.forEach((dist, i) => {
					if (dist.id === 'citymail') {
						Object.assign(dist, { enabled: false })
					} else if (dist.id === this.ineligibleChoice) {
						this.tab = i
					}
				})
			}
			this.ineligibleDone = true
		},
	},
	mounted() {
		this.$store.dispatch('resetOrderData')
		if (!this.initialized) {
			this.initialized = true
			const workflow = this.$store.state.workflow.join('/')
			let tabIsInitialized = false
			fetchBookingDates({ apiToken: this.apiToken, workflow })
				.then((bookingDates) =>	{
					this.distributions.forEach((dist, i) => {
						const [distributor, tmpdist] = dist.path
						const distribution = workflow === 'fileupload' ? 'fileupload' : tmpdist

						if (bookingDates[distributor] && bookingDates[distributor][distribution]) {
							Object.assign(dist.dates, bookingDates[distributor][distribution])
							if (!tabIsInitialized) {
								tabIsInitialized = true
								this.tab = i
							}
						} else if (!bookingDates[distributor]) {
							if (dist.id === 'citymail') {
								if (this.citymailIneligible) {
									this.ineligibleChoice = 'postnord'
									this.ineligibleDone = true
								}
							}
							Object.assign(dist, { enabled: false })
						}
						// TODO Should this be rewritten now that we have one maxAmount?
						Object.assign(dist, this.getMaxAmountForAdr)
						if (this.addressCount > this.getMaxAmountForAdr) {
							Object.assign(dist, { enabled: false })
						}
					})
				})
				.finally(() => { this.ready = true })
		}
	},
}
</script>

<style scoped lang="scss">
@import "./Distributions.scss";
</style>