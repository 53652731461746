<template>
  <ViewContainer>
		<h1>Access Denied</h1>
		<p>Access has been denied, this is probably due to an expired token or object</p>
  </ViewContainer>
</template>
<script>
import ViewContainer from '../components/ViewContainer.vue'

export default {
	components: { ViewContainer },
	name: 'Unauthorized',
}
</script>
<style lang="scss" scoped>
  @import './Unauthorized.scss';
</style>
