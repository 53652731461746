<template>
  <div>
    <v-card-text>
			<div
			v-if="distribution.extraDescription && citymailEligible.length > 0"
			class="extra-description"
			>
			{{ distribution.extraDescription }}
		</div>
			{{ distribution.description }}
      <order-info
        :distributor="distribution.path[0]"
        :product="distribution.path[1]"
      />
    </v-card-text>
    <v-divider />
    <v-list v-if="addressCount > maxAmount">
      <v-card-text>
				<p>
					<v-icon color="warning" :style="{ verticalAlign: 'baseline' }"
						>fas fa-exclamation-triangle</v-icon
					>
					{{ $t("MaxAmounts.Dialog3a") }}
				</p>
				<p>
					{{ $t("MaxAmounts.Dialog3b") }} {{ addressCount - maxAmount }}
				</p>
      </v-card-text>
    </v-list>
    <v-list v-else>
      <v-radio-group
        v-if="ready"
        v-model="distribution.dateSelected"
        @change="$emit('handleDateSelect', index)"
      >
        <v-list-item
          :key="date.id"
          v-for="date in distribution.dates"
          class="x-dense"
        >
          <v-radio :label="date.description" :value="date.id">
            <template slot="label">
              <label class="datelabel">
                {{ date.description }}
              </label>
            </template>
          </v-radio>
        </v-list-item>
      </v-radio-group>
    </v-list>
  </div>
</template>

<script>
import OrderInfo from '../../OrderInfo'

export default {
	name: 'Distribution',
	props: ['distribution', 'index', 'ready', 'addressCount', 'maxAmount', 'citymailEligible'],
	components: {
		OrderInfo,
	},
}
</script>

<style scoped lang='scss'>
@import "./DistributionDates.scss";
</style>