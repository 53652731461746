<template>
  <ViewContainer>
		<file-upload-panel />
	</ViewContainer>
</template>
<script>
import ViewContainer from '../components/ViewContainer.vue'
import FileUploadPanel from '../components/FileUploadPanel'

export default {
	name: 'FileUpload',
	components: { ViewContainer, FileUploadPanel },
	data: () => ({}),
}
</script>
<style lang="scss" scoped>
@import "./FileUpload.scss";
</style>