import { getAxiosClient } from '../httpClient'

const cache = {}
let request = false

const serviceURL = process.env.VUE_APP_MAPAPI_URL

export function fetchMunicipalities({ apiToken }) {
	if (cache.municipalities) {
		return Promise.resolve(cache.municipalities)
	}	if (request) {
		return request
	}
	request = getAxiosClient({ apiToken }).get(
		`${serviceURL}/data/municipalities`,
	).then(({ data }) => {
		cache.municipalities = data
		return data
	})
	return request
}