<template>
	<div>
    <v-card-subtitle>Beställare</v-card-subtitle>
    <v-card-text>
      <form name="customer" v-on:submit.prevent="onSubmit">
        <v-text-field
          hide-details="auto"
          dense
          label="Namn (Företag):"
          v-model="companyName"
          outlined
					@change="handleSyncToStore"
        ></v-text-field>
        <v-text-field
          hide-details="auto"
          dense
          label="Användare:"
          v-model="brokerName"
          outlined
					@change="handleSyncToStore"
        ></v-text-field>
        <v-text-field
          hide-details="auto"
          dense
          label="Adress:"
          v-model="address"
          outlined
					@change="handleSyncToStore"
        ></v-text-field>
        <v-text-field
          hide-details="auto"
          dense
          label="Postnr och ort"
          v-model="postcode"
          outlined
					@change="handleSyncToStore"
        ></v-text-field>
      </form>
    </v-card-text>
	</div>
</template>

<script>
export default {
	name: 'OrderReference',
	data: () => ({
		companyName: '',
		brokerName: '',
		address: '',
		postcode: '',
	}),
	computed: {
		config() {
			/* { address, city, postcode, customerName, departmentName, firstName, lastName} */
			return this.$store.state.session.userInfo
		},
	},
	methods: {
		handleSyncToStore() {
			this.$store.dispatch('updateOrderReference', {
				companyName: this.companyName,
				brokerName: this.brokerName,
				address: this.address,
				postcode: this.postcode,
			})
		},
		populate(config) {
			this.companyName = `${config.customerName} ${config.departmentName}`
			this.brokerName = `${config.firstName} ${config.lastName}`
			this.address = config.address
			this.postcode = config.postcode
		},
	},
	mounted() {
		this.populate(this.config)
		this.handleSyncToStore()
	},
	watch: {
		config(incoming) {
			this.populate(incoming)
		},
	},
}
</script>

<style scoped lang="scss">
	@import './OrderReference.scss'
</style>