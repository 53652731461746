<template>
  <ViewContainer>
		<holiday-homes-panel />
	</ViewContainer>
</template>
<script>
import ViewContainer from '../components/ViewContainer.vue'
import HolidayHomesPanel from '../components/HolidayHomesPanel'

export default {
	name: 'FileUpload',
	components: { HolidayHomesPanel, ViewContainer },
	data: () => ({}),
}
</script>
<style lang="scss" scoped>
@import "./HolidayHomes.scss";
</style>