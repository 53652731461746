import { getAxiosClient } from '../httpClient'

const serviceURL = process.env.VUE_APP_MAPAPI_URL

const validate = {
	distributor: (distributor) => ['citymail'].some((d) => d === distributor),
	zipcodes: (zipcodes) => zipcodes.every(zipcode => !isNaN(+zipcode)),
}

// Seems like IIS is limited to handle about 2048 chars for the URL (the path excluded belive)
// So we resort to batch the requests in chunks of 25 just to be sure.
const makeBatches = (size, source) => {
	const chop = (index = 0, target = []) => {
		const nextIndex = index + size < source.length ? index + size : source.length
		const isLast = nextIndex === source.length
		const chunks = [...target, source.slice(index, nextIndex)]
		return (isLast
			? chunks
			: chop(nextIndex, chunks)
		)
	}
	return chop(0)
}

export const checkDistributorZipcodes = async ({ apiToken, distributor, zipcodes }) => {
	if (!validate.distributor(distributor)) throw new Error(`Invalid distributor: ${distributor}`)
	if (!validate.zipcodes(zipcodes)) throw new Error('error in zipcodes, not a zipcode')

	const batches = makeBatches(25, zipcodes)

	const responses = await Promise.all(batches.map((batch) => getAxiosClient({ apiToken }).get(
		`${serviceURL}/addresses/check-zipcodes`,
		{ params:
						{
							distributor,
							zipcodes: batch,
						},
		},
	)
		.then(({ data }) => data)))

	return responses.reduce((results, { valid, invalid }) => ({
		distributor,
		valid: [...results.valid, ...valid],
		invalid: [...results.invalid, ...invalid],
	}), { valid: [], invalid: [], distributor })
}